import h from "../helpers";
import copy from "copy-to-clipboard";
import { setVar, setVarPersist } from "./actionCreators";
import {
    flowRelatedRequestStart,
    flowRelatedRequestCompleted,
    treeRequestStart,
    treeRequestCompleted,
} from "./loadingActions";
import { ToggleIsLayoutTreeLoading } from "./treeBehaviorActions";
import { IScriptObject, ILoadScriptObjects, ILoadUIDBScriptObjects } from "../types/stores/vars";
import { request } from "../helpers/httpInterceptor";
import {
    FieldRateByFieldId,
    TaxmanFieldRateByFieldId,
    FieldDistributionCountsById,
    LoadEnabledFieldLabelsAction,
    LoadEnabledFieldLabelsAction3,
    LoadEnabledFieldVisibilityTypesAction,
    LoadFieldRatesAction,
    LoadTaxmanFieldRatesAction,
    LoadFieldDistributionCountsAction,
    LOAD_FIELD_RATES,
    LOAD_TAXMAN_FIELD_RATES,
    LOAD_DISTRIBUTION_COUNTS,
} from "../types/stores/fieldsByCompany";

if (!top.RDX) {
    top.RDX = {};
}

let _fieldsLastLoadedByTable = {};
let _haveTablesChanged = true;
let _lastTablesChangedDate = Date.parse("2000-01-25T10:18:18Z");

export const requestScriptObjects = scriptType => dispatch => {
    let url = `/Scripts/GetFullScriptAccess?scriptType=${scriptType}`;
    dispatch(incAjaxCount());
    dispatch(flowRelatedRequestStart());
    dispatch(treeRequestStart());
    dispatch(ToggleIsLayoutTreeLoading(true));
    return request(
        url,
        {
            credentials: "same-origin",
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        },
        dispatch
    )
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            dispatch(decAjaxCount());
            dispatch(flowRelatedRequestCompleted());
            dispatch(treeRequestCompleted());
            dispatch(ToggleIsLayoutTreeLoading(false));

            if (data.results) {
                if (scriptType == 0) {
                    dispatch(loadScriptObjects(data.results));
                } else {
                    dispatch(loadUIDBScriptObjects(data.results));
                }
            } else {
                throw new Error("Couldn't load script objects.");
            }

            //TODO123 checksum work
            // if (data.checksum) {
            //     dispatch(setVarPersist("scriptObjsChecksum", data.checksum));
            // } else {
            //     throw new Error("Couldn't load script objects checksum.");
            // }
        })
        .catch(error => {
            dispatch(decAjaxCount());
            dispatch(flowRelatedRequestCompleted());
            dispatch(treeRequestCompleted());
            dispatch(ToggleIsLayoutTreeLoading(false));

            h.error("Error loading script objects.", error);
        });
};
top.RDX.requestScriptObjects = () => top.store.dispatch(requestScriptObjects());

export const searchColumns = filter => dispatch => {
    let url = `/Scripts/SearchColumns/${filter}`;

    dispatch(incAjaxCount());
    dispatch(flowRelatedRequestStart());
    dispatch(treeRequestStart());
    dispatch(ToggleIsLayoutTreeLoading(true));

    return request(
        url,
        {
            credentials: "same-origin",
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        },
        dispatch
    )
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            dispatch(decAjaxCount());
            dispatch(flowRelatedRequestCompleted());
            dispatch(treeRequestCompleted());
            dispatch(ToggleIsLayoutTreeLoading(false));

            if (data.columns) {
                dispatch(loadScriptObjects(data.columns));
            } else {
                throw new Error("Couldn't load script objects.");
            }
        })
        .catch(error => {
            dispatch(decAjaxCount());
            dispatch(flowRelatedRequestCompleted());
            dispatch(treeRequestCompleted());
            dispatch(ToggleIsLayoutTreeLoading(false));

            h.error("Error loading script objects.", error);
        });
};
top.RDX.searchColumns = () => top.store.dispatch(searchColumns());

export const requestAudienceCounts = companyId => dispatch => {
    let url = "/api/audiencecounts/GetAudienceCounts?companyId=" + companyId;
    return request(url, { credentials: "same-origin" }, dispatch)
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            if (data.audiencecounts) {
                dispatch(loadFieldDistributionCounts(data.audiencecounts));
            } else {
                throw new Error("Couldn't load Audience Counts.");
            }
        })
        .catch(error => {
            h.error("Error loading Audience Counts.", error);
        });
};
top.RDX.requestAudienceCounts = companyId => top.store.dispatch(requestAudienceCounts(companyId));

export const requestFieldTreeLastUpdated = tableKey => async dispatch => {
    let urlDates = "/Tree/FieldTablesLastUpdated";
    try {
        const response = await request(urlDates, { credentials: "same-origin" }, dispatch);
        const response1 = await h.checkStatus(response);
        const data = await h.toJson(response1);
        if (data) {
            let highestDate = Date.parse(data[0].DateUpdated);
            if (highestDate > _lastTablesChangedDate) {
                _lastTablesChangedDate = highestDate;
                _haveTablesChanged = true;
                dispatch(requestFieldTree(tableKey));
            } else {
                _haveTablesChanged = false;
            }
        }
    } catch (error) {
        h.error("Error loading field tree Last Updated.", error);
    }
};
top.RDX.requestFieldTreeLastUpdated = tableKey => top.store.dispatch(requestFieldTreeLastUpdated(tableKey));

// tableKey - an optional tableKey to load fields for
export const requestFieldTree =
    (tableKey, dontRequestAgainMinutes = 0) =>
    (dispatch, getState) => {
        const state = getState();
        const features = state.session.enabledFeatures || [];
        const enhancedFiledLoading = features.includes("use-enhanced-field-loading");
        const useFieldTreeTabs = features.includes("field-tree-tabs");
        if (enhancedFiledLoading) {
            dispatch(requestFieldTreeLastUpdated(tableKey));
            if (!_haveTablesChanged) {
                return;
            }
        }
        let url = "/Tree/Combined";
        if (tableKey) {
            url += "/" + tableKey;
        }
        tableKey = tableKey || 0;
        const lastLoadedTableKey = state.vars.fieldTreeLastTableKeyLoaded || 0;
        const companyId = getState().session.companyId;

        // Optional Parameter:  If dontRequestAgainMinutes = 5, then don't do any requests if we
        // asked for the field tree in the last five minutes.
        // Also, if table key is changing, then ignore this.
        const localCachedValueExists =
            _fieldsLastLoadedByTable[tableKey] != null &&
            dateDiffMinutes(_fieldsLastLoadedByTable[tableKey], new Date()) < dontRequestAgainMinutes;
        const tableKeyChanged = tableKey != lastLoadedTableKey;
        if (!tableKeyChanged && localCachedValueExists) {
            return;
        }
        _fieldsLastLoadedByTable[tableKey] = new Date();

        dispatch(incAjaxCount());
        dispatch(flowRelatedRequestStart());
        dispatch(treeRequestStart());
        dispatch(ToggleIsLayoutTreeLoading(true));
        return request(url, { credentials: "same-origin" }, dispatch)
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                dispatch(decAjaxCount());
                dispatch(flowRelatedRequestCompleted());
                dispatch(treeRequestCompleted());
                dispatch(ToggleIsLayoutTreeLoading(false));

                if (data.fields) {
                    dispatch(loadFields(data.fields, tableKey));
                    if (useFieldTreeTabs) {
                        dispatch(setTreeTabs(data.fieldsExperian, data.fieldsMarketplace, data.fieldsMyData));
                    }
                    if (data.enabledFieldLabels) {
                        dispatch(loadEnabledFieldLabels(data.enabledFieldLabels));
                    }
                    if (data.enabledFieldLabels3) {
                        dispatch(loadEnabledFieldLabels3(data.enabledFieldLabels3));
                    }
                    if (data.enabledFieldVisibilityTypes) {
                        dispatch(loadEnabledFieldVisibilityTypes(data.enabledFieldVisibilityTypes));
                    }
                    if (data.visibleFieldByCompanyUsers) {
                        dispatch(loadVisibilityByCompanyUsers(data.visibleFieldByCompanyUsers));
                    }
                    if (data.fieldRates) {
                        dispatch(loadFieldRates(data.fieldRates));
                    }
                    if (data.taxmanFieldRates) {
                        dispatch(loadTaxmanFieldRates(data.taxmanFieldRates));
                    }

                    const getDistributionCounts = features.includes("display-distribution-counts");

                    if (getDistributionCounts) {
                        dispatch(requestAudienceCounts(companyId));
                    }
                } else {
                    throw new Error("Couldn't load fields.");
                }
                if (data.checksum) {
                    dispatch(setVarPersist("fieldsChecksum", data.checksum));
                } else {
                    throw new Error("Couldn't load fields checksum.");
                }
            })
            .catch(error => {
                dispatch(decAjaxCount());
                dispatch(flowRelatedRequestCompleted());
                dispatch(treeRequestCompleted());
                dispatch(ToggleIsLayoutTreeLoading(false));

                h.error("Error loading field tree.", error);
            });
    };
top.RDX.requestFieldTree = tableKey => top.store.dispatch(requestFieldTree(tableKey));

export const requestCompanyFieldTree = companyId => dispatch => {
    let url = "/Tree/CompanyCombined/" + companyId;
    dispatch(incAjaxCount());
    dispatch(treeRequestStart());

    return request(url, { credentials: "same-origin" }, dispatch)
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            dispatch(decAjaxCount());
            dispatch(treeRequestCompleted());

            if (data.fields) {
                dispatch(loadFields(data.fields, 0));
                if (data.enabledFieldLabels) {
                    dispatch(loadEnabledFieldLabels(data.enabledFieldLabels));
                }
                if (data.enabledFieldLabels3) {
                    dispatch(loadEnabledFieldLabels3(data.enabledFieldLabels3));
                }
                if (data.enabledFieldVisibilityTypes) {
                    dispatch(loadEnabledFieldVisibilityTypes(data.enabledFieldVisibilityTypes));
                }
                if (data.visibleFieldByCompanyUsers) {
                    dispatch(loadVisibilityByCompanyUsers(data.visibleFieldByCompanyUsers));
                }
                if (data.fieldRates) {
                    dispatch(loadFieldRates(data.fieldRates));
                }
                if (data.taxmanFieldRates) {
                    dispatch(loadTaxmanFieldRates(data.taxmanFieldRates));
                }
            } else {
                throw new Error("Couldn't load fields.");
            }
        })
        .catch(error => {
            dispatch(decAjaxCount());
            dispatch(treeRequestCompleted());

            h.error("Error loading company field tree.", error);
        });
};

// This is a way to use requestFieldTree(), but if we don't know which tableKey we 'should'
// be looking at, we will use the last tableKey that was used for a fieldTree request
// example:
//    User selects a table with <TablePicker> - and we load fields for TableKey = 39
//    Server needs to tell the client to update Fields, but it can't run requestFieldTree,
//       because that would load fields for TableKey = 0.
//    Server calls this instead, which finds TableKey=39 in the state, automatically set by
//    the vars reducer, and updates fields for tablekey=39.
export const requestFieldTreeForLastTablekeyUsed = () => (dispatch, getState) => {
    const state = getState();
    const tableKey = state.vars.fieldTreeLastTableKeyLoaded || 0;
    dispatch(requestFieldTree(tableKey));
};
top.RDX.requestFieldTreeForLastTablekeyUsed = () => top.store.dispatch(requestFieldTreeForLastTablekeyUsed());

export const requestFieldTreeSetTableImmediately = tableKey => dispatch => {
    dispatch(setTableKey(tableKey)); //Setting immediately prevents Tree/Combined from being called multiple times from CreateFlowApp
    dispatch(requestFieldTree(tableKey));
};
top.RDX.requestFieldTreeSetTableImmediately = tableKey =>
    top.store.dispatch(requestFieldTreeSetTableImmediately(tableKey));

// Special version of Tree for Edit Fields page
export const requestEditFieldTree =
    (isBulkAction = 0) =>
    (dispatch, getState) => {
        const url = `/Tree/Combined?editFields=1&isBulkAction=${isBulkAction}`;
        const state = getState();
        const features = state.session.enabledFeatures || [];
        const useFieldTreeTabs = features.includes("field-tree-tabs");
        dispatch(incAjaxCount());
        return (
            request(url, { credentials: "same-origin" }, dispatch)
                /* eslint-disable no-console */
                .then(h.checkStatus)
                .then(console.log("Tree/Combined:checkStatus" + Date().toLocaleString() + ""))
                .then(h.toJson)
                .then(console.log("Tree/Combined:toJson" + Date().toLocaleString() + ""))
                .then(data => {
                    dispatch(decAjaxCount());
                    if (data.fields) {
                        dispatch(loadFields(data.fields, 0));
                        if (useFieldTreeTabs) {
                            dispatch(
                                setTreeTabs(
                                    data.fieldsExperian,
                                    data.fieldsMarketplace,
                                    data.fieldsMyData,
                                    data.fieldsOther
                                )
                            );
                        }
                        if (data.enabledFieldLabels) {
                            dispatch(loadEnabledFieldLabels(data.enabledFieldLabels));
                        }
                        if (data.enabledFieldVisibilityTypes) {
                            dispatch(loadEnabledFieldVisibilityTypes(data.enabledFieldVisibilityTypes));
                        }
                        if (data.visibleFieldByCompanyUsers) {
                            dispatch(loadVisibilityByCompanyUsers(data.visibleFieldByCompanyUsers));
                        }
                        if (isBulkAction == 0) {
                            if (data.enabledFieldLabels3) {
                                dispatch(loadEnabledFieldLabels3(data.enabledFieldLabels3));
                            }
                            if (data.fieldRates) {
                                dispatch(loadFieldRates(data.fieldRates));
                            }
                            if (data.taxmanFieldRates) {
                                dispatch(loadTaxmanFieldRates(data.taxmanFieldRates));
                            }
                        }
                    } else {
                        throw new Error("Couldn't load fields.");
                    }
                })
                .catch(error => {
                    h.error("Error loading field tree.", error);
                    dispatch(decAjaxCount());
                })
        );
    };
top.RDX.requestEditFieldTree = () => top.store.dispatch(requestEditFieldTree());

export const requestEditLayoutFieldTree = () => dispatch => {
    const url = "/Tree/LayoutCombined?editFields=1";
    return (
        request(url, { credentials: "same-origin" }, dispatch)
            /* eslint-disable no-console */
            .then(h.checkStatus)
            .then(console.log("LayoutCombined:checkStatus" + Date().toLocaleString() + ""))
            .then(h.toJson)
            .then(console.log("LayoutCombined:toJson" + Date().toLocaleString() + ""))
            .then(data => {
                if (data.fields) {
                    dispatch(loadLayoutFields(data.fields));
                } else {
                    throw new Error("Couldn't layout load fields.");
                }
                if (data.enabledFieldVisibilityTypes) {
                    dispatch(loadEnabledFieldVisibilityTypes(data.enabledFieldVisibilityTypes));
                }
            })
            .catch(error => {
                h.error("Error loading layout field tree.", error);
            })
    );
};
top.RDX.requestEditLayoutFieldTree = () => top.store.dispatch(requestEditLayoutFieldTree());

export const requestEditReportFieldTree = () => dispatch => {
    const url = "/Tree/ReportCombined?editFields=1";
    return request(url, { credentials: "same-origin" }, dispatch)
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            if (data.fields) {
                dispatch(loadReportFields(data.fields));
            } else {
                throw new Error("Couldn't report load fields.");
            }
            if (data.enabledFieldVisibilityTypes) {
                dispatch(loadEnabledFieldVisibilityTypes(data.enabledFieldVisibilityTypes));
            }
            if (data.fieldRates) {
                dispatch(loadFieldRates(data.fieldRates));
            }
            if (data.taxmanFieldRates) {
                dispatch(loadTaxmanFieldRates(data.taxmanFieldRates));
            }
        })
        .catch(error => {
            h.error("Error loading report field tree.", error);
        });
};
top.RDX.requestEditReportFieldTree = () => top.store.dispatch(requestEditReportFieldTree());

export const requestEnabledFieldCompanies = () => dispatch => {
    const url = "/Field/GetEnabledFieldCompanies";
    dispatch(incAjaxCount());
    return request(
        url,
        {
            credentials: "same-origin",
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        },
        dispatch
    )
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            dispatch(loadEnabledFieldCompanies(data.fieldCompanies));
            dispatch(decAjaxCount());
        })
        .catch(error => {
            h.error("Error loading field companies.", error);
            dispatch(decAjaxCount());
        });
};

export const loadEnabledFieldLabels = (enabledFieldLabels): LoadEnabledFieldLabelsAction => ({
    type: "LOAD_ENABLED_FIELD_LABELS",
    enabledFieldLabels,
});

export const loadEnabledFieldLabels3 = (enabledFieldLabels3): LoadEnabledFieldLabelsAction3 => ({
    type: "LOAD_ENABLED_FIELD_LABELS3",
    enabledFieldLabels3,
});

export const loadEnabledFieldVisibilityTypes = (
    enabledFieldVisibilityTypes
): LoadEnabledFieldVisibilityTypesAction => ({
    type: "LOAD_ENABLED_FIELD_VISIBILITY_TYPES",
    enabledFieldVisibilityTypes,
});

export const loadEnabledFieldCompanies = enabledFieldCompanies => ({
    type: "LOAD_ENABLED_FIELD_COMPANIES",
    enabledFieldCompanies,
});

export const loadVisibilityByCompanyUsers = visibilityByCompanyUsers => ({
    type: "LOAD_VISIBILITY_COMPANY_USERS",
    visibilityByCompanyUsers,
});

export const loadFieldRates = (fieldRates: FieldRateByFieldId): LoadFieldRatesAction => ({
    type: LOAD_FIELD_RATES,
    fieldRates,
});

export const loadTaxmanFieldRates = (taxmanFieldRates: TaxmanFieldRateByFieldId): LoadTaxmanFieldRatesAction => ({
    type: LOAD_TAXMAN_FIELD_RATES,
    taxmanFieldRates,
});

export const loadFieldDistributionCounts = (
    fieldDistributionCounts: FieldDistributionCountsById
): LoadFieldDistributionCountsAction => ({
    type: LOAD_DISTRIBUTION_COUNTS,
    fieldDistributionCounts,
});

export const setTableKey = (tableKey = 0) => ({
    type: "SET_LAST_TABLEKEY",
    tableKey,
});
top.RDX.setTableKey = tableKey => top.store.dispatch(setTableKey(tableKey));

export const requestFieldTreeIfNeeded = () => (dispatch, getState) => {
    const state = getState();
    if (state.fields == null || state.fields.byId == null || Object.keys(state.fields.byId).length == 0) {
        dispatch(requestFieldTreeForLastTablekeyUsed());
    }
};
export const loadScriptObjects = (objects: Array<IScriptObject>): ILoadScriptObjects => ({
    type: "LOAD_SCRIPT_OBJECTS",
    objects,
});
top.RDX.loadScriptObjects = objects => top.store.dispatch(loadScriptObjects(objects));

export const loadUIDBScriptObjects = (objects: Array<IScriptObject>): ILoadUIDBScriptObjects => ({
    type: "LOAD_UIDB_SCRIPT_OBJECTS",
    objects,
});
top.RDX.loadUIDBScriptObjects = objects => top.store.dispatch(loadUIDBScriptObjects(objects));

export const loadFields =
    (fields, tableKey = 0) =>
    dispatch => {
        dispatch({
            type: "LOAD_FIELDS",
            fields,
            tableKey,
        });
    };
top.RDX.loadFields = (fields, tableKey) => top.store.dispatch(loadFields(fields, tableKey));

export const setTreeTabs =
    (fieldsExperian, fieldsMarketplace, fieldsMyData, fieldsOther = false) =>
    dispatch => {
        dispatch({
            type: "SET_FIELD_TAB_PERMISSIONS",
            fieldsExperian,
            fieldsMarketplace,
            fieldsMyData,
            fieldsOther,
        });
    };
top.RDX.setTreeTabs = (fieldsExperian, fieldsMarketplace, fieldsMyData, fieldsOther) =>
    top.store.dispatch(setTreeTabs(fieldsExperian, fieldsMarketplace, fieldsMyData, fieldsOther));

export const requestReportFieldTree = () => dispatch => {
    dispatch(ToggleIsLayoutTreeLoading(true));
    const url = "/Tree/ReportCombined";
    return fetch(url, { credentials: "same-origin" })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            dispatch(ToggleIsLayoutTreeLoading(false));

            if (data.fields) {
                dispatch(loadReportFields(data.fields));
            } else {
                throw new Error("Couldn't report load fields.");
            }
            if (data.checksum) {
                dispatch(setVar("reportFieldsChecksum", data.checksum));
            } else {
                throw new Error("Couldn't load report fields checksum.");
            }
            if (data.enabledFieldVisibilityTypes) {
                dispatch(loadEnabledFieldVisibilityTypes(data.enabledFieldVisibilityTypes));
            }
            if (data.fieldRates) {
                dispatch(loadFieldRates(data.fieldRates));
            }
            if (data.taxmanFieldRates) {
                dispatch(loadTaxmanFieldRates(data.taxmanFieldRates));
            }
        })
        .catch(error => {
            dispatch(ToggleIsLayoutTreeLoading(false));
            h.error("Error loading report field tree.", error);
        });
};
top.RDX.requestReportFieldTree = () => top.store.dispatch(requestReportFieldTree());

export const requestLayoutFieldTree =
    () =>
    dispatch => {
        dispatch(ToggleIsLayoutTreeLoading(true));
        const url = `/Tree/LayoutCombined?editFields=0&experianOnly=0`;
        return (
            fetch(url, { credentials: "same-origin" })
                /* eslint-disable no-console */
                .then(h.checkStatus)
                .then(
                    console.log(
                        "LayoutCombinededitFields=0&experianOnly=0:checkStatus" +
                            Date().toLocaleString() +
                            ""
                    )
                )
                .then(h.toJson)
                .then(
                    console.log(
                        "LayoutCombinededitFields=0&experianOnly=0:toJson" + Date().toLocaleString() + ""
                    )
                )
                .then(data => {
                    dispatch(ToggleIsLayoutTreeLoading(false));

                    if (data.fields) {
                        dispatch(loadLayoutFields(data.fields));
                    } else {
                        throw new Error("Couldn't layout load fields.");
                    }
                    if (data.checksum) {
                        dispatch(setVar("layoutFieldsChecksum", data.checksum));
                    } else {
                        throw new Error("Couldn't load layout fields checksum.");
                    }
                    if (data.enabledFieldVisibilityTypes) {
                        dispatch(loadEnabledFieldVisibilityTypes(data.enabledFieldVisibilityTypes));
                    }
                    if (data.fieldRates) {
                        dispatch(loadFieldRates(data.fieldRates));
                    }
                    if (data.taxmanFieldRates) {
                        dispatch(loadTaxmanFieldRates(data.taxmanFieldRates));
                    }
                })
                .catch(error => {
                    dispatch(ToggleIsLayoutTreeLoading(false));
                    h.error("Error loading layout field tree.", error);
                })
        );
    };
top.RDX.requestLayoutFieldTree = () => top.store.dispatch(requestLayoutFieldTree());

export const requestLayoutExperianFieldTree = () => dispatch => {
    dispatch(ToggleIsLayoutTreeLoading(true));
    const url = "/Tree/LayoutCombined?editFields=0&experianOnly=1";
    return (
        fetch(url, { credentials: "same-origin" })
            /* eslint-disable no-console */
            .then(h.checkStatus)
            .then(console.log("LayoutCombinededitFields=0&experianOnly=1:checkStatus" + Date().toLocaleString() + ""))
            .then(h.toJson)
            .then(console.log("LayoutCombinededitFields=0&experianOnly=1:toJson" + Date().toLocaleString() + ""))
            .then(data => {
                dispatch(ToggleIsLayoutTreeLoading(false));

                if (data.fields) {
                    dispatch(loadLayoutFields(data.fields));
                } else {
                    throw new Error("Couldn't load Experian layout fields.");
                }
                if (data.checksum) {
                    dispatch(setVar("layoutFieldsChecksum", data.checksum));
                } else {
                    throw new Error("Couldn't load Experian layout fields checksum.");
                }
                if (data.fieldRates) {
                    dispatch(loadFieldRates(data.fieldRates));
                }
                if (data.taxmanFieldRates) {
                    dispatch(loadTaxmanFieldRates(data.taxmanFieldRates));
                }
            })
            .catch(error => {
                dispatch(ToggleIsLayoutTreeLoading(false));
                h.error("Error loading Experian layout field tree.", error);
            })
    );
};
top.RDX.requestLayoutExperianFieldTree = () => top.store.dispatch(requestLayoutExperianFieldTree());

export const requestFullFieldTree = () => dispatch => {
    dispatch(ToggleIsLayoutTreeLoading(true));
    const url = "/Tree/FullFieldTree";
    return fetch(url, { credentials: "same-origin" })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            dispatch(ToggleIsLayoutTreeLoading(false));
            if (data) {
                dispatch(loadFields(data, 0));
            } else {
                throw new Error("Couldn't load full field tree.");
            }
        })
        .catch(error => {
            dispatch(ToggleIsLayoutTreeLoading(false));
            h.error("Error loading full field tree.", error);
        });
};
top.RDX.requestFullFieldTree = () => top.store.dispatch(requestFullFieldTree());

export const loadLayoutFields = layoutfields => ({
    type: "LOAD_LAYOUT_FIELDS",
    layoutfields,
});
top.RDX.loadLayoutFields = reportFields => top.store.dispatch(loadLayoutFields(reportFields));

export const loadReportFields = reportFields => ({
    type: "LOAD_REPORT_FIELDS",
    reportFields,
});
top.RDX.loadReportFields = reportFields => top.store.dispatch(loadReportFields(reportFields));

export const copytoClip = fieldKey => (dispatch, getState) => {
    const state = getState();
    if (!state.fields || !state.fields.byId) {
        console.warn("copytoClip:  Fields aren't loaded."); // eslint-disable-line no-console
        return;
    }
    let copytxt = state.fields.byId[fieldKey].text;
    if (state.fields.byId[fieldKey].leaf_description != null) {
        copytxt += " - " + state.fields.byId[fieldKey].leaf_description;
    }
    copy(copytxt);
};

// QueryBuilder specific - add a selected value to the "fieldData" from the fieldtree
const addSelectedValuesToFieldData = (fieldData: string, selectedValues: any) => {
    if (selectedValues == null) {
        return fieldData;
    }
    const parsedData = JSON.parse(fieldData.replace(/'/g, '"'));
    const selected = { value: selectedValues };
    return JSON.stringify({ rules: [Object.assign(parsedData.rules[0], selected)] });
};

/*
field example:
companyid : 0
created_dt : "2017-01-18T09:36:39.413"
data : "{'rules':[{'id':'10079','operator':'in'}]}"
detail_description : null
fieldkey : 10079
id : 10079
isLeaf : true
leaf_description : "Likelihood to purchase a new vehicle"
parent : "14"
sort : 5
sourcekey : 23
text : "New Vehicle (A300)"
type : "detail"
viewid : 4
*/

export const setSelectedField = fieldKey => ({
    type: "SET_SELECTED_FIELD",
    fieldKey,
});
export const clearSelectedField = () => ({
    type: "CLEAR_SELECTED_FIELD",
});
export const setSelectedFields = fieldKey => ({
    type: "SET_SELECTED_FIELDS",
    fieldKey,
});
export const clearSelectedFields = () => ({
    type: "CLEAR_SELECTED_FIELDS",
});
export const setEditFolder = isEditFolder => ({
    type: SET_PROPERTY,
    property: AdminDesignerProperties.isEditFolder,
    newValue: isEditFolder,
});

export const setAddField = isAddField => ({
    type: SET_PROPERTY,
    property: AdminDesignerProperties.isAddField,
    newValue: isAddField,
});
export const addTempField = field => ({
    type: "ADD_TEMP_FIELD",
    field,
});

// ========================================================
const _filtersLastLoadedByCompany = {};
import { dateDiffMinutes, incAjaxCount, decAjaxCount } from "./actionCreators";

export const requestFilterTree =
    (force: boolean = false) =>
    (dispatch, getState) => {
        const companyId = getState().session.companyId;
        if (
            !force &&
            _filtersLastLoadedByCompany[companyId] != null &&
            dateDiffMinutes(_filtersLastLoadedByCompany[companyId], new Date()) < 30
        ) {
            return;
        }

        const url = "/Tree/Filters";
        return fetch(url, { credentials: "same-origin" })
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                if (data.filters) {
                    dispatch(loadFilters(data.filters));
                    _filtersLastLoadedByCompany[companyId] = new Date();
                } else {
                    throw new Error("Couldn't load filters.");
                }
                if (data.checksum) {
                    dispatch(setVarPersist("filtersChecksum", data.checksum));
                } else {
                    throw new Error("Couldn't load filters checksum.");
                }
            })
            .catch(error => {
                h.error("Error loading filters.", error);
            });
    };
top.RDX.requestFilterTree = (force = false) => top.store.dispatch(requestFilterTree(force));

export const loadFilters = filters => ({
    type: "LOAD_FILTERS",
    filters,
});
top.RDX.loadFilters = filters => top.store.dispatch(loadFilters(filters));

export const requestNewFieldFolder =
    (parentId, folderName = "New Folder") =>
    dispatch => {
        if (!parentId) {
            return;
        }

        // Update database
        const url = `/Field/CreateNewFieldFolder?parentId=${parentId}&folderName=${folderName}`;
        dispatch(incAjaxCount());
        return (
            fetch(url, {
                credentials: "same-origin",
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            })
                /* eslint-disable no-console */
                .then(h.checkStatus)
                .then(console.log("CreateNewFieldFolder:checkStatus" + Date().toLocaleString() + ""))
                .then(h.toJson)
                .then(console.log("CreateNewFieldFolder:toJson" + Date().toLocaleString() + ""))
                .then(data => {
                    dispatch(decAjaxCount());
                    if (data.folderId > 0) {
                        // Reload Fields
                        dispatch(requestEditFieldTree());
                        dispatch(requestEditLayoutFieldTree());
                        // set the new folder to renaming state
                        dispatch(startRenamingFieldFolder(data.folderId));
                    } else {
                        h.error("Error creating new folder.");
                    }
                })
                .catch(error => {
                    h.error("Error creating new folder.", error);
                })
        );
    };
top.RDX.requestNewFieldFolder = (parentId, folderName) =>
    top.store.dispatch(requestNewFieldFolder(parentId, folderName));

export const requestRenameFieldFolder = (folderId, newFolderName) => dispatch => {
    if (!folderId || !newFolderName || newFolderName == "") {
        return;
    }

    // Update database
    const url = `/Field/RenameFieldFolder?folderId=${folderId}&newFolderName=${newFolderName}`;
    dispatch(incAjaxCount());
    return (
        fetch(url, {
            credentials: "same-origin",
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        })
            /* eslint-disable no-console */
            .then(h.checkStatus)
            .then(console.log("RenameFieldFolder:checkStatus" + Date().toLocaleString() + ""))
            .then(h.toJson)
            .then(console.log("RenameFieldFolder:toJson" + Date().toLocaleString() + ""))
            .then(data => {
                dispatch(decAjaxCount());
                if (data.success) {
                    // Update state
                    dispatch(renameFieldFolder(folderId, newFolderName));
                    // clear renaming state
                    dispatch(stopRenamingFieldFolder(folderId));
                } else {
                    h.error("Error renaming folder.");
                }
            })
            .catch(error => {
                h.error("Error renaming folder.", error);
                dispatch(decAjaxCount());
            })
    );
};
top.RDX.requestRenameFieldFolder = (folderId, newFolderName) =>
    top.store.dispatch(requestRenameFieldFolder(folderId, newFolderName));

export const requestDeleteFieldFolder = folderId => dispatch => {
    if (!folderId) {
        return;
    }

    // Update database
    const url = `/Field/DeleteFieldFolder?folderId=${folderId}`;
    dispatch(incAjaxCount());
    return (
        fetch(url, {
            credentials: "same-origin",
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        })
            /* eslint-disable no-console */
            .then(h.checkStatus)
            .then(console.log("DeleteFieldFolder:checkStatus" + Date().toLocaleString() + ""))
            .then(h.toJson)
            .then(console.log("DeleteFieldFolder:toJson" + Date().toLocaleString() + ""))
            .then(data => {
                dispatch(decAjaxCount());
                if (data.success) {
                    // Update state
                    //dispatch(deleteFieldFolder(folderId));
                    // Reload tree
                    dispatch(requestEditFieldTree());
                    dispatch(requestEditLayoutFieldTree());
                } else {
                    h.error("Error deleting folder.");
                }
            })
            .catch(error => {
                h.error("Error deleting folder.", error);
                dispatch(decAjaxCount());
            })
    );
};
top.RDX.requestDeleteFieldFolder = folderId => top.store.dispatch(requestDeleteFieldFolder(folderId));

export const requestMoveFieldToFolder = (fieldId, newFolderId) => dispatch => {
    if (!fieldId || !newFolderId) {
        return;
    }

    // Update database
    const url = `/Field/UpdateFieldParentFolder?fieldId=${fieldId}&newFolderId=${newFolderId}`;
    dispatch(incAjaxCount());
    return fetch(url, {
        credentials: "same-origin",
        method: "POST",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
        },
    })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            dispatch(decAjaxCount());
            if (data.success) {
                dispatch(requestEditFieldTree());
                dispatch(requestEditLayoutFieldTree());
                // Update state
                dispatch(moveFieldToFolder(fieldId, newFolderId));
            } else {
                h.error("Error moving field.");
            }
        })
        .catch(error => {
            h.error("Error moving field.", error);
        });
};
top.RDX.requestMoveFieldToFolder = (fieldId, newFolderId) =>
    top.store.dispatch(requestMoveFieldToFolder(fieldId, newFolderId));

export const requestMoveFieldsToFolder = (selectedFields, newFolderId) => dispatch => {
    if (!selectedFields || selectedFields.length == 0 || !newFolderId) {
        return;
    }

    dispatch(incAjaxCount());
    return fetch(`/Field/UpdateFieldsParentFolder`, {
        credentials: "same-origin",
        method: "POST",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({ selectedFields, newFolderId }),
    })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(data => {
            dispatch(decAjaxCount());
            if (data.success) {
                dispatch(requestEditFieldTree());
                dispatch(requestEditLayoutFieldTree());
                // Update state
                dispatch(moveFieldsToFolder(selectedFields, newFolderId));
            } else {
                h.error("Error moving fields.");
            }
        })
        .catch(error => {
            h.error("Error moving fields.", error);
        });
};
top.RDX.requestMoveFieldTsoFolder = (selectedFields, newFolderId) =>
    top.store.dispatch(requestMoveFieldsToFolder(selectedFields, newFolderId));

// In the UI, mark a folder as being renamed
export const startRenamingFieldFolder = folderId => ({
    type: "START_RENAMING_FIELD_FOLDER",
    folderId,
});

// Stop renaming folder
export const stopRenamingFieldFolder = folderId => ({
    type: "STOP_RENAMING_FIELD_FOLDER",
    folderId,
});

export const renameFieldFolder = (folderId, newFolderName) => ({
    type: "RENAME_FIELD_FOLDER",
    folderId,
    newFolderName,
});

export const moveFieldToFolder = (fieldId, newFolderId) => ({
    type: "MOVE_FIELD_TO_FOLDER",
    fieldId,
    newFolderId,
});

export const moveFieldsToFolder = (fieldIds, newFolderId) => ({
    type: "MOVE_FIELDS_TO_FOLDER",
    fieldIds,
    newFolderId,
});

export const deleteFieldFolder = folderId => ({
    type: "DELETE_FIELD_FOLDER",
    folderId,
});

// Add measure field that is an actual field
import { SET_PROPERTY, AdminDesignerProperties } from "../reducers/adminDesigner";

// BEGIN ===Generic QB Field To Add Queues===
// Puts the queue in "state.vars"
// Assumes there will only be one QueryBuilder on the screen at a time.
// Only used by FlowItemEdit currently.
export const genericQbClearFieldToAdd = fieldKey => ({
    type: "GENERIC_QB_CLEAR_FIELD",
    fieldKey,
});

export const genericQbAddField =
    (fieldKey, index, selectedValues, group = null) =>
    (dispatch, getState) => {
        const state = getState();
        if (!state.fields || !state.fields.byId) {
            console.warn("Fields aren't loaded."); // eslint-disable-line no-console
            return;
        }

        if (!state.fields.byId[fieldKey]) {
            /* eslint-disable no-console */
            console.warn(`I was told to add [${fieldKey}], but I can't find a field with that fieldKey.`);
            /* eslint-enable no-console */
            return;
        }

        let fieldData = state.fields.byId[fieldKey].data;
        fieldData = addSelectedValuesToFieldData(fieldData, selectedValues);

        dispatch({
            type: "GENERIC_QB_ADD_FIELD",
            fieldKey,
            fieldData,
            group,
            index,
        });
    };
export const genericQbAddFieldMulti =
    (selectedFields, index, selectedValues, group = null) =>
    (dispatch, getState) => {
        const state = getState();

        let fieldsWithKeyAndData = [];

        Array.prototype.forEach.call(selectedFields, field => {
            const fieldKey = parseInt(field);
            let data = state.fields.byId[fieldKey].data;
            const fieldData = addSelectedValuesToFieldData(data, selectedValues);
            fieldsWithKeyAndData.push({ fieldKey, fieldData });
        });

        dispatch({
            type: "GENERIC_QB_ADD_FIELD_MULTI",
            fieldsWithKeyAndData,
            group,
            index,
        });
    };
// END ===Generic QB Field To Add Queues===

//begin field-filters-on-demand

export const loadRequestedFilters = filters => ({
    type: "LOAD_REQUESTED_FILTERS",
    filters,
});

export const removeRequestedFilter = filterId => ({
    type: "REMOVE_REQUESTED_FILTER",
    filterId,
});

export const requestFieldFilters = fieldIds => dispatch => {
    dispatch(incAjaxCount());
    fetch("/Tree/FiltersForFields", {
        method: "POST",
        headers: { Accept: "application/json", "Content-Type": "application/json" },
        body: JSON.stringify({ fieldIds }),
        credentials: "same-origin",
    })
        /* eslint-disable no-console */
        .then(h.checkStatus)
        .then(console.log("FiltersForFields:checkStatus" + Date().toLocaleString() + ""))
        .then(h.toJson)
        .then(console.log("FiltersForFields:toJson" + Date().toLocaleString() + ""))
        .then(data => {
            dispatch(loadRequestedFilters(data.filters));
            dispatch(decAjaxCount());
        })
        .catch(error => {
            dispatch(decAjaxCount());
            h.error("Error loading filters", error);
        });
};

//end field-filters-on-demand
