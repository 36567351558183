import clone from "../helpers/clone";
import mergeDeep from "../helpers/mergeDeep";
import { createSelector } from "reselect";
import { ISessionStore, UserSettings, SessionCompany } from "../types/stores/session";
import { IAppState } from "../types/stores";

const SET_USERID = "SET_USERID";
const CLEAR_USERID = "CLEAR_USERID";
const SET_SESSION_COMPANIES = "SET_SESSION_COMPANIES";
const SET_COMPANYID = "SET_COMPANYID";
const CLEAR_COMPANYID = "CLEAR_COMPANYID";
const SET_ENABLED_FEATURES = "SET_ENABLED_FEATURES";
const SET_ISINTERNAL = "SET_ISINTERNAL";
const SET_SESSION = "SET_SESSION";

type SetUserIdAction = {
    type: typeof SET_USERID;
    userId: number | null;
};

type ClearUserIdAction = {
    type: typeof CLEAR_USERID;
};
type SetSessionCompaniesAction = {
    type: typeof SET_SESSION_COMPANIES;
    companies: Array<SessionCompany>;
};

type SetCompanyIdAction = {
    type: typeof SET_COMPANYID;
    companyId: number | null;
};

type ClearCompanyIdAction = {
    type: typeof CLEAR_COMPANYID;
};

type SetEnabledFeaturesAction = {
    type: typeof SET_ENABLED_FEATURES;
    enabledFeatures: Array<string>;
};

type SetIsInternalAction = {
    type: typeof SET_ISINTERNAL;
    isInternal: boolean;
};

type SetSessionAction = {
    type: typeof SET_SESSION;
    sessionInfo: ISessionStore;
};

type ActionType =
    | SetUserIdAction
    | ClearUserIdAction
    | SetSessionCompaniesAction
    | SetCompanyIdAction
    | ClearCompanyIdAction
    | SetEnabledFeaturesAction
    | SetIsInternalAction
    | SetSessionAction;

const defaultUserSettings: UserSettings = {
    "display-audience-json-text": "",
    "flow-deploy-notify": "",
    "model-notify": "",
    "conceptual-search-default": "",
    "time-zone": "",
    "time-zone-iana": "",
};

const defaultState: ISessionStore = {
    userId: null,
    userEmail: "",
    userLogonId: "",
    companyId: null,
    companies: [],
    defaultParentFolderIds: [],
    isCompanyHasManageAccess: false,
    enabledFeatures: [],
    isInternal: null,
    isAuthenticated: false,
    site: "",
    sitesAvailable: [],
    userSettings: defaultUserSettings,
    flowItemTypes: [],
    partnersAllowingIdFieldsAccess: [],
    caseBalanceIsOffDefault: false,
    companyIsPreventOverrideDedupeExport: true,
    companyIsCustomDedupeDefault: false,
    companyIsDedupeDefault: false,
    companyIsDestination: false,
    companyIsTrackingEnabled: false,
    companyResultsDB: null,
    companySchema: null,
    companyUsersDataDB: null,
    companyWarehouseDB: null,
    customizations: [],
    dedupeSortByFields: null,
    defaultDedupeKey: null,
    defaultCopyOfferCodesOn: false,
    defaultCopyVariablesOn: false,
    environment: null,
    firstName: null,
    fullName: null,
    hasUsedSSO: false,
    lastName: null,
    passwordExpiredDaysLeft: null,
    pwExpired: false,
    roles: [],
    splitBalanceIsOffDefault: false,
    ueServiceId: 0,
    DataRetentionDaysDefault: 0,
    SortSplitOrderBy: null,
    dedupeSettings: [],
    fileDelimiters: [],
    gtagMeasurementId: "",
};

function session(state = defaultState, action: ActionType): ISessionStore {
    switch (action.type) {
        case SET_USERID:
            return { ...state, userId: action.userId };
        case CLEAR_USERID:
            return { ...state, userId: null };
        case SET_SESSION_COMPANIES:
            return { ...state, companies: action.companies };
        case SET_COMPANYID:
            return { ...state, companyId: action.companyId };
        case CLEAR_COMPANYID:
            return { ...state, companyId: null };
        case SET_ENABLED_FEATURES:
            return { ...state, enabledFeatures: action.enabledFeatures };
        case SET_ISINTERNAL:
            return { ...state, isInternal: action.isInternal };
        case SET_SESSION:
            return mergeDeep(clone(state), action.sessionInfo);
        default:
            return state;
    }
}
export default session;

export const getIsInFlowsItem = createSelector(
    (state: IAppState) => state.router,
    router => {
        const pathname = router && router.location && router.location.pathname;
        return Boolean(pathname && pathname.includes("/flows") && pathname.includes("item"));
    }
);
